import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { useContext, useMemo } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import CurrencyInput from 'app/components/commons/Currency/CurrencyInput/CurrencyInput';
import DatePickerWithHelper from 'app/components/fields/DatePickerWithHelper/DatePickerWithHelper';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { GiftCard, GiftCardEditForm } from 'app/typings/giftCard';
import { parseInputNumber } from 'app/utils/typing';

import EmailEditForm from './EmailEditForm/EmailEditForm';
import './GiftCardEdit.scss';
import PhysicalEditForm from './PhysicalEditForm/PhysicalEditForm';
import PrintEditForm from './PrintEditForm/PrintEditForm';

const RULES = [{ required: true, message: 'Required' }];

export const GiftCardEdit = ({
  visible,
  giftCard,
  onCancel,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm();
  const { currencySymbol } = useContext(CurrencyContext);

  const isNotGiftCard =
    giftCard.giftCardType === 'voucher' ||
    giftCard.giftCardType === 'incentive' ||
    giftCard.giftCardType === 'internal';

  const formValues = useMemo(() => {
    const commonProperties = {
      expirationDate: new Date(giftCard.expirationDate ?? new Date()),
      remainingValue: giftCard.remainingValue,
      beneficiaryName: giftCard.beneficiaryName,
    };

    if (
      giftCard.giftCardType === 'voucher' ||
      giftCard.giftCardType === 'internal'
    ) {
      return {
        ...commonProperties,
        minAmount: giftCard.minAmount,
      };
    }

    if (giftCard.giftCardType === 'incentive') {
      return {
        ...commonProperties,
        startDate: new Date(giftCard.startDate ?? new Date()),
        minAmount: giftCard.minAmount,
        maxUse: giftCard.maxUse,
      };
    }

    if (giftCard.giftCardType === 'print') {
      return {
        ...commonProperties,
        senderName: giftCard.senderName,
        color: giftCard.color,
        message: giftCard.message,
      };
    }

    if (giftCard.giftCardType === 'email') {
      return {
        ...commonProperties,
        senderName: giftCard.senderName,
        color: giftCard.color,
        message: giftCard.message,
        beneficiaryEmail: giftCard.beneficiaryEmail,
        billingEmail: giftCard.billingEmail,
        emailSendDate: new Date(giftCard.emailSendDate ?? new Date()),
      };
    }

    return {
      ...commonProperties,
      senderName: giftCard.senderName,
      shippingFirstName: giftCard.shippingFirstName,
      shippingLastName: giftCard.shippingLastName,
      shippingStreet1: giftCard.shippingStreet1,
      shippingStreet2: giftCard.shippingStreet2,
      shippingZipCode: giftCard.shippingZipCode,
      shippingCity: giftCard.shippingCity,
      shippingCountryId: giftCard.shippingCountryId,
    };
  }, [giftCard]);

  const onFinish = (values: any) => {
    const updatedGiftCard: GiftCardEditForm = {
      ...values,
      emailSendDate: values.emailSendDate?.format('YYYY-MM-DD'),
    };

    onSubmit(updatedGiftCard);
  };

  const isFormInvalid =
    !form.isFieldsTouched() ||
    form.getFieldsError().filter(({ errors }) => errors.length > 0).length > 0;

  const specificForm = () => {
    if (isNotGiftCard) {
      return null;
    }

    if (giftCard.giftCardType === 'print') {
      return <PrintEditForm />;
    }

    if (giftCard.giftCardType === 'email') {
      return <EmailEditForm />;
    }

    return <PhysicalEditForm />;
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={onFinish}
      initialValues={formValues}
    >
      {
        // This form relies on the render prop syntax to get access to updated form values during render.
        // TODO try to make this form work without the render prop syntax
        (currentFormValues) => (
          <Modal
            width={660}
            onCancel={onCancel}
            okText="Save"
            open={visible}
            title="Edit gift card"
            centered
            className="gift-card-modal"
            footer={[
              <Button
                type="primary"
                onClick={() => form.submit()}
                key="save"
                disabled={isFormInvalid}
              >
                Save
              </Button>,
            ]}
          >
            <>
              {giftCard.giftCardType === 'incentive' && (
                <div className="input-row">
                  <Form.Item name="startDate" label="Start date" rules={RULES}>
                    <DatePickerWithHelper />
                  </Form.Item>
                </div>
              )}
              <div className="input-row">
                <Form.Item
                  name="expirationDate"
                  label="Expiry date"
                  rules={RULES}
                >
                  <DatePickerWithHelper />
                </Form.Item>
              </div>
              <div className="input-row">
                <Form.Item
                  name="remainingValue"
                  label="Remaining Value"
                  rules={RULES}
                >
                  <CurrencyInput
                    min={0}
                    currency={currencySymbol}
                    parser={parseInputNumber}
                  />
                </Form.Item>
              </div>
              {!!isNotGiftCard && (
                <div className="input-row">
                  <Form.Item
                    name="minAmount"
                    label="Minimum code use amount"
                    rules={RULES}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                </div>
              )}
              {giftCard.giftCardType === 'incentive' && (
                <div className="input-row">
                  <Form.Item name="maxUse" label="Max use" rules={RULES}>
                    <InputNumber min={1} />
                  </Form.Item>
                </div>
              )}
              {!isNotGiftCard && (
                <div className="input-row">
                  <Form.Item
                    name="senderName"
                    label="Sender Name"
                    rules={RULES}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
              <div className="input-row">
                <Form.Item
                  name="beneficiaryName"
                  label="Beneficiary Name"
                  rules={RULES}
                >
                  <Input />
                </Form.Item>
              </div>
              {specificForm()}
            </>
          </Modal>
        )
      }
    </FormLegacyRenderProp>
  );
};

type Props = {
  visible: boolean;
  giftCard: GiftCard;
  onCancel: () => void;
  onSubmit: (body: GiftCardEditForm) => void;
};

export default GiftCardEdit;
