import { Layout } from 'antd';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SDetailLayout } from 'app/components/StaycationUI';
import {
  useGetPackageCopywriting,
  useGetPackageDisclaimer,
} from 'app/hooks/data/packages/usePackages';
import { useSavePackageDisclaimer } from 'app/hooks/data/packages/useSavePackage';
import { DisclaimerKind, PackageDisclaimer } from 'app/typings/packages';

import DisclaimerCategory from './components/DisclaimerCategory/DisclaimerCategory';

const { Content } = Layout;

type Mode = 'view' | 'edit';

type Props = {
  headerMenu: ReactNode;
  headerExtra: Array<ReactNode>;
  packageId: number | null;
};

const noop = () => {};

export const Disclaimer = ({ headerMenu, headerExtra, packageId }: Props) => {
  const { data: packageCopywriting } = useGetPackageCopywriting(packageId);
  const { mutateAsync: saveDisclaimers } = useSavePackageDisclaimer();

  const { data: disclaimers, isLoading: disclaimersLoading } =
    useGetPackageDisclaimer(packageId);
  const [disclaimersValues, setDisclaimersValues] = useState<
    Array<PackageDisclaimer>
  >([]);

  useEffect(() => {
    if (!disclaimersLoading && disclaimers) {
      setDisclaimersValues(disclaimers);
    }
  }, [disclaimersLoading, disclaimers]);

  const disclaimersAdvice = useMemo(
    () =>
      disclaimersValues
        .filter((d) => d.kind === DisclaimerKind.ADVICE)
        .sort((a, b) => a.priority - b.priority),
    [disclaimersValues]
  );
  const disclaimersAlert = useMemo(
    () =>
      disclaimersValues
        .filter((d) => d.kind === DisclaimerKind.ALERT)
        .sort((a, b) => a.priority - b.priority),
    [disclaimersValues]
  );

  const [mode, setMode] = useState<Mode>('view');
  const [isDirty, setIsDirty] = useState(false);

  const onEdit = useCallback(() => {
    setMode('edit');
  }, []);

  const onCancel = useCallback(() => {
    setIsDirty(false);
    setMode('view');
  }, []);

  const onChange = useCallback(
    (disclaimersChanged: Array<PackageDisclaimer>, kind: DisclaimerKind) => {
      const newDisclaimersAdvice =
        kind === DisclaimerKind.ADVICE ? disclaimersChanged : disclaimersAdvice;
      const newDisclaimersAlert =
        kind === DisclaimerKind.ALERT ? disclaimersChanged : disclaimersAlert;

      const newDisclaimers = newDisclaimersAdvice.concat(newDisclaimersAlert);

      const isDisclaimersValid = newDisclaimers.every(
        (d) => d.text.length && d.startDate
      );

      setDisclaimersValues(newDisclaimers);
      setIsDirty(isDisclaimersValid);
    },
    [disclaimersAdvice, disclaimersAlert]
  );

  const onSave = useCallback(() => {
    if (packageId) {
      saveDisclaimers({ packageId, payload: disclaimersValues });
    }

    setIsDirty(false);
    setMode('view');
  }, [packageId, saveDisclaimers, disclaimersValues]);

  useEffect(() => {
    if (mode === 'view' && disclaimers) {
      // We reset the page with the value stored in the state when the mode is changed to view
      setDisclaimersValues(disclaimers);
    }
  }, [mode, disclaimers]);

  if (!packageId) {
    return null;
  }

  return (
    <SDetailLayout
      title={packageCopywriting?.name ?? `Package #${packageId}`}
      mode={mode}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      reset={noop}
      isDirty={isDirty}
      headerMenu={headerMenu}
      headerExtra={headerExtra}
      isValid
    >
      <Content className="package-detail__content">
        <DisclaimerCategory
          mode={mode}
          kind={DisclaimerKind.ADVICE}
          onValueChanged={onChange}
          disclaimers={disclaimersAdvice}
          packageId={packageId}
        />
        <DisclaimerCategory
          mode={mode}
          kind={DisclaimerKind.ALERT}
          onValueChanged={onChange}
          disclaimers={disclaimersAlert}
          packageId={packageId}
        />
      </Content>
    </SDetailLayout>
  );
};

export default Disclaimer;
